import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Link from "../components/link"
import Layout from "../layouts/de"
import CompanyPageBase from "../templates/company"
import Intro from "../components/intro"
import Figure from "../components/figure"
import History from "../components/history"
import TextSection from "../components/text-section"
import Box from "../components/box"
import Download from "../components/download"
import EnvironmentStats from "../components/stats-environment"
import Teaser from "../components/teaser"

export default () => {
  const { fileNodeIatf, fileNodeIso, heroImage, policyImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { glob: "company-hero/*.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        policyImage: file(relativePath: { eq: "mitarbeiter-an-maschine.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        fileNodeIatf: sanityDownload(
          file: {de: {fileName: {eq: "finkernagel_certificate_iatf-16949-2016-19a5e3b531808470612f4c05ebf0e689"}}}
        ) {
          id
          title {
            de
          }
          file {
            de {
              fileName
              file {
                asset {
                  extension
                  size
                }
              }
            }
          }
        }
        fileNodeIso: sanityDownload(
          file: {de: {fileName: {eq: "finkernagel_certificate_iso_9001-2015-6782fa28a7d8cca262d6524aa89b6be7"}}}
        ) {
          id
          title {
            de
          }
          file {
            de {
              fileName
              file {
                asset {
                  extension
                  size
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <CompanyPageBase locale="de">
        <Intro title="Draht und Umformwerkzeuge aus einer Hand">
          <p>
            Wir versorgen Zulieferer aus der Automobil-, Bau-, Elektro- und
            Maschinenbauindustrie mit Kaltstauchdraht und Umformwerkzeugen für
            höchste Anforderungen. Unsere qualifizierten Mitarbeiter, modernste
            Maschinen und Anlagen sowie Oberflächenprüfung mittels Wirbelstrom
            und konsequente Einzelringverfolgung machen uns in unserem
            Marktsegment zu einem der führenden Anbieter.
          </p>
        </Intro>
        <Figure
          image={heroImage.childImageSharp.gatsbyImageData}
          alt="Produktionsanlagen"
          caption="Traditionsbewusstsein und modernste Technik schließen sich nicht aus: Unsere Produktions&shy;anlagen sind auf dem neuesten Stand."
        />
        <History />
        <TextSection title="Wir sind Finkernagel">
          <div className="mx-auto mb-sm lg:ml-0">
            <div className="relative h-0 pt-3/2 overflow-hidden">
              <div className="absolute left-0 top-0 h-full w-full">
                <iframe
                  title="YouTube Video"
                  className="w-full h-full"
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/8HI2fTHKsFQ?rel=0"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </TextSection>
        <TextSection title="Qualität">
          <p>
            Motivierte und kontinuierlich weitergebildete Mitarbeiter setzen die
            anspruchsvollen Forderungen und Wünsche unserer Kunden um. Dabei
            stehen wir für hochwertige Produkte, Termintreue und kompetenten
            Service.
          </p>

          <Box title="Wir sind nach EN ISO 9001 und IATF 16949 zertifiziert.">
            <Download extension={fileNodeIatf.file.de.file.asset.extension}
              fileName={`/static/${fileNodeIatf.file.de.fileName}.${fileNodeIatf.file.de.file.asset.extension}`} 
              key={fileNodeIatf.id} 
              title={fileNodeIatf.title.de} 
              size={fileNodeIatf.file.de.file.asset.size}/>
            <Download extension={fileNodeIso.file.de.file.asset.extension}
              fileName={`/static/${fileNodeIso.file.de.fileName}.${fileNodeIso.file.de.file.asset.extension}`} 
              key={fileNodeIso.id} 
              title={fileNodeIso.title.de} 
              size={fileNodeIso.file.de.file.asset.size}/>
          </Box>
        </TextSection>
        <TextSection title="Umwelt">
          <p>
            Im Rahmen unserer Nachhaltigkeits&shy;strategie haben wir unter
            anderem <strong>1.850 Solar-Module</strong> auf den Dachflächen
            unserer Produktionshallen installiert. Dies entspricht einer
            Nenn&shy;leistung von <strong>515 kWh</strong>. Damit können
            jährlich bis zu{" "}
            <strong>
              300 Tonnen CO<sub>2</sub>
            </strong>
             eingespart werden.
          </p>
          <EnvironmentStats />
        </TextSection>
        <Teaser image={policyImage}>
          <h1>Unsere Unternehmens&shy;politik</h1>
          <p>
            Wir sind ein erfolgreiches, unabhängiges Familienunternehmen mit
            einer international anerkannten Stellung im Markt. Unsere
            Mitarbeiter beteiligen sich aktiv an der Umsetzung unserer
            Unternehmenspolitik.
          </p>
          <Link to="/policy" className="accessory">
            <FormattedMessage id="global.explore" />
          </Link>
        </Teaser>
      </CompanyPageBase>
    </Layout>
  )
}
